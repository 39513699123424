<template>
  <div class="lpr">
    <!-- <LeftBar class="navbar" /> -->
    <iframe
      src="/subscription/index.html"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
    <AllowAudio v-if="showAllowAudio" @closemodal="closeAllowAudioModal" />
  </div>
</template>

<script>
// import LeftBar from '@/components/LeftBar.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'LPR',
  data() {
    return {
      // msgEvent: null
    }
  },
  components: {
    // LeftBar,
    AllowAudio: () => import('@/components/AllowAudio.vue'),
  },
  computed: {
    ...mapState(['showAllowAudio', 'userFlatObj'])
  },
  watch: {
    'userFlatObj.language'() {
      document.querySelector('iframe').contentWindow.location.reload(true)
    }
  },
  methods: {
    ...mapMutations('history', ['updateShowExportPanel']),
    closeAllowAudioModal() {
      this.$store.commit('updateShowAllowAudio', false)  // 之後不需再顯示跳窗詢問
    },
    msgEventFunc(e) {
      const { event } = e.data
      // 銜接 車號管理 匯出 報表
      if (event === 'reportCreated') {
        // this.updateShowExportPanel(true)
        // 安全性處理：https://developer.mozilla.org/zh-CN/docs/Web/API/Window/postMessage#%E5%AE%89%E5%85%A8%E9%97%AE%E9%A2%98
        if (e.origin === window.location.origin) {
          this.updateShowExportPanel(true)
        }
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.msgEventFunc)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.msgEventFunc)
  }
}
</script>

<style scoped>
.lpr {
  display: flex;
  width: 100vw;
  height: 100vh;
}

/* .navbar {
  width: 50px;
  height: 100%;
} */
</style>
